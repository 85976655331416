import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "@emotion/styled";
import AppStore from "./AppStore";
import { Logo } from "../../layout/Logo";
import VideoBannerComponent from "./VideoMobile/VideoBanner";

const BannerContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 5em;
  display: grid;
  grid-gap: 1em;
  padding: 1em;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
  position: relative;
  z-index: 1;
  ::after {
    content: " ";
    background-image: ${(props) => `url(${props.background})`};
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: cover;
    position: absolute;
    width: 90%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    left: -100%;
    right: 0;
    z-index: -1;
    @media (max-width: 700px) {
      width: 100%;
    }
    @media (max-width: 592px) {
      left: -50%;
      top: 2%;
      transform: rotate(-13deg);
    }
    @media (max-width: 380px) {
      left: -35%;
      transform: rotate(-6deg);
    }
  }
  div:nth-of-type(1) {
    @media (max-width: 591px) {
      order: 1;
    }
  }
  .wrapper-image {
    max-width: 250px;
    margin: 0 auto;
  }
  .wrapper-text {
    max-width: 900px;
    margin: 0 auto;
    padding: 2em 1em;
    & h1 {
      margin: 0.5em 0em;
      background: linear-gradient(
        to left bottom,
        #f9993c,
        #fb9f33,
        #fca628,
        #fcad1a,
        #fbb400
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: clamp(1.4rem, 3vw, 2.9rem);
    }
    & h2 {
      margin: 0.5em 0em;
      font-weight: 600;
      font-size: clamp(1.3rem, 2.5vw, 1.8rem);
    }
    & h5 {
      margin: 0;
      font-weight: 300;
      font-size: clamp(0.9rem, 2.5vw, 0.9rem);
    }
    .sub-text {
      font-size: clamp(1rem, 2.5vw, 1.1rem);
      font-weight: 400;
    }
    @media (max-width: 592px) {
      text-align: center;
      & .app-store {
        justify-content: center;
      }
    }
  }
`;

const AppBanner = () => {
  const { elementBackground } = useStaticQuery(graphql`
    query {
      elementBackground: file(relativePath: { eq: "svg/MobileBanner.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <BannerContainer background={elementBackground.publicURL}>
      <div
        style={{
          paddingTop: "1.5em",
        }}
      >
        <VideoBannerComponent />
      </div>

      <div className="wrapper-text">
        <Logo style={{ maxWidth: 150 }} />
        <h1>KUU Creator</h1>
        <h2>
          Powerful yet easy to use <br />
          <div className="sub-text">
            Perfect app for online sellers, entrepreneurs, and creators
          </div>
        </h2>

        <AppStore />
      </div>
    </BannerContainer>
  );
};

export default AppBanner;
