import React from "react";
import styled from "@emotion/styled";
import AppStore from "./AppStore";
import VideoComponent from "./VideoMobile/index";

const WrapperContainer = styled.div`
  .container {
    max-width: 1170px;
    width: 100%;
    margin: 0 auto;
    padding: 1em 1em;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
    position: relative;
    ::before {
      content: " ";
      position: absolute;
      top: 0;
      left: -20%;
      right: 0;
      bottom: 0;
      clip-path: circle(35%);
      width: 80%;
      height: 100%;
      background-image: linear-gradient(
        to right bottom,
        #fbb400,
        #ff9c1f,
        #ff8534,
        #ff6d47,
        #ff5858
      );

      @media (max-width: 660px) {
        left: -23%;
      }
      @media (max-width: 575px) {
        bottom: 0%;
        left: 0%;
        top: unset;
        width: 100%;
        height: 60%;
        clip-path: circle(40%);
      }
    }
    div:nth-of-type(1) {
      @media (max-width: 575px) {
        order: 1;
      }
    }
  }
  .container-image {
    max-width: 250px;
    margin: 0 auto;
  }
  .container-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    ::before {
      content: " ";
      position: absolute;
      top: -20px;
      right: 20%;
      bottom: 0;
      clip-path: circle(30%);
      width: 20%;
      height: 20%;
      background-color: #ff7044;
      @media (max-width: 670px) {
        content: unset;
      }
    }

    & h1 {
      margin: 0.5em 0;
      font-size: clamp(1.4rem, 2.5vw, 1.8rem);
      @media (max-width: 382px) {
        & br {
          display: none;
        }
      }
    }
    & p {
      font-weight: 400;
      margin: 0.5em 0;
    }
    & h5 {
      margin: 0;
      font-weight: 300;
      font-size: clamp(0.9rem, 2.5vw, 0.9rem);
    }
    @media (max-width: 592px) {
      text-align: center;
      & .app-store {
        justify-content: center;
      }
    }
  }
  .wrapper-video {
    padding: 1.5em 0em;
  }
`;

const AppPower = () => {
  return (
    <WrapperContainer>
      <div className="container">
        <div className="wrapper-video">
          <VideoComponent />
        </div>
        <div className="container-text">
          <h1>
            Automatically remove background & create professional look image
            <br /> in no time
          </h1>
          <p>
            Our AI-powered background removal tool give you precise result in a
            tap. Create amazing work even without any prior editing skills.
            Share to any social media or e-commerce platforms right from your
            mobile device, anytime, anywhere.
          </p>
          <AppStore />
        </div>
      </div>
    </WrapperContainer>
  );
};

export default AppPower;
