import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import BannerVideo from "../../../../images/Mobile/VideoPower.mp4";

const ContainerImage = styled.div`
  max-width: 250px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  position: relative;
  .frame {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 5px;
    left: 0;
  }
`;

const VideoBannerComponent = () => {
  return (
    <ContainerImage>
      <StaticImage
        placeholder="tracedSVG"
        imgStyle={{ zIndex: 1 }}
        quality="40"
        src="../../../../images/Mobile/iphone.png"
        alt="screen"
      />

      <div className="frame">
        <video
          loading="lazy"
          autoPlay
          muted
          loop
          playsInline
          style={{
            borderRadius: 26,
            display: "block",
            width: "100%",
            maxWidth: 229,
            margin: "0 auto",
            height: "auto",
          }}
        >
          <source src={BannerVideo} type="video/mp4" />
        </video>
      </div>
    </ContainerImage>
  );
};

export default VideoBannerComponent;
