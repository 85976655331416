import React from "react";
import { graphql } from "gatsby";
import MobileLayout from "../../components/Mobile/layout/index";
import AppBanner from "../../components/Mobile/components/AppBanner";
import AppPromote from "../../components/Mobile/components/AppPromote";
import AboutApp from "../../components/Mobile/components/AboutApp";
import AppPower from "../../components/Mobile/components/AppPower";
import AppShowCase from "../../components/Mobile/components/AppShowCase";

const Mobile = () => {
  return (
    <MobileLayout>
      <div>
        <AppBanner />
      </div>
      <div>
        <AppShowCase />
      </div>
      <div>
        <AppPower />
      </div>
      <div>
        <AboutApp />
      </div>
      <div
        style={{
          backgroundColor: "#f4f4f4",
        }}
      >
        <AppPromote />
      </div>
    </MobileLayout>
  );
};

export default Mobile;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
