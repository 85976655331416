import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "@emotion/styled";
import AppStore from "./AppStore";

const WrapperComponent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .container {
    display: grid;
    grid-auto-columns: auto;
    padding: 0em 1em;
    justify-items: center;
    & h3 {
      margin: 0.5em;
      font-size: clamp(1.5rem, 2.7vw, 2.5rem);
    }
    & h5 {
      font-weight: 300;
      margin: 0.5em;
      font-size: clamp(1rem, 2.5vw, 1.2rem);
    }
  }
`;

export const WrapperText = styled.div`
  text-align: center;
  position: relative;
  z-index: 1;
  max-width: 900px;
  width: 100%;
  height: 100%;
  margin: 1em auto;
  display: flex;
  justify-content: center;
  align-items: center;
  ::before {
    content: " ";
    background-image: ${(props) => `url(${props.backgroundLeft})`};
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    position: absolute;
    top: 4%;
    left: 0%;
    max-width: 200px;
    width: 100%;
    height: 50%;
    z-index: -1;
    @media (max-width: 600px) {
      max-width: 120px;
      top: -6%;
    }
  }
  ::after {
    content: " ";
    background-image: ${(props) => `url(${props.backgroundRight})`};
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    position: absolute;
    bottom: 0%;
    right: 0%;
    max-width: 150px;
    width: 100%;
    height: 50%;
    z-index: -1;
    @media (max-width: 600px) {
      max-width: 90px;
      bottom: -10%;
    }
  }
`;

const AppPromote = () => {
  const { rightIconWebKuu, leftIconWebKuu } = useStaticQuery(graphql`
    query {
      rightIconWebKuu: file(relativePath: { eq: "svg/RightIconWebKuu.svg" }) {
        publicURL
      }
      leftIconWebKuu: file(relativePath: { eq: "svg/LeftIconWebKuu.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <WrapperComponent>
      <WrapperText
        backgroundRight={rightIconWebKuu.publicURL}
        backgroundLeft={leftIconWebKuu.publicURL}
      >
        <div className="container">
          <h3>
            Grow your business <br /> Make your life easier
          </h3>
          <AppStore />
        </div>
      </WrapperText>
    </WrapperComponent>
  );
};

export default AppPromote;
