import React from "react";
import styled from "@emotion/styled";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import "swiper/css";
import { GatsbyImage } from "gatsby-plugin-image";

SwiperCore.use([Pagination]);

const SwiperContent = styled(Swiper)`
  .swiper-slide {
    margin-bottom: 2em;
  }
  .swiper-pagination {
    display: ${(props) => props.display};
    bottom: 0px;
  }
  .swiper-pagination-bullet-active {
    background-color: #fbb400;
  }
`;

const SlideImage = (props) => {
  const { imageObject, view, display, text } = props;
  const slides = imageObject.edges.map(({ node }, index) => (
    <div className="image-card">
      <GatsbyImage
        style={{ border: "1px solid rgba(64,87,109,.07)", borderRadius: 10 }}
        image={node.childImageSharp.gatsbyImageData}
        alt={node.base}
      />
      {text[index]}
    </div>
  ));

  return (
    <>
      <SwiperContent
        display={display}
        spaceBetween={15}
        slidesPerView={view ? view : 3}
        pagination={{ clickable: true }}
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {slides.map((slideContent, index) => (
          <SwiperSlide key={index}>{slideContent}</SwiperSlide>
        ))}
      </SwiperContent>
    </>
  );
};

SlideImage.defaultProps = {
  type: "image",
  display: "block",
};

const SlideContent = (props) => {
  const { image, view, type, display, text } = props;
  return (
    <>
      <div
        style={{
          width: "100%",
        }}
      >
        <SlideImage
          imageObject={image}
          view={view}
          type={type}
          text={text}
          display={display}
        />
      </div>
    </>
  );
};

export default SlideContent;
