import React, { memo } from "react";
import { SvgIcon } from "@material-ui/core";

const LogoFooter = memo(({ onClick }) => {
  return (
    <SvgIcon
      onClick={onClick}
      style={{
        width: "auto",
        height: "50px",
        cursor: "pointer",
      }}
      viewBox="0 0 466.13 156.02"
    >
      <defs>
        <style>
          {
            ".prefix__cls-10{fill:#fcfdff}.prefix__cls-20{fill:#f2af1c}.prefix__cls-50{fill:#080909}"
          }
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <path
            className="prefix__cls-10"
            d="M410.52 105.05c-.41 0-41.57-.52-41.57-45.43v-55a4.6 4.6 0 119.2 0v55c0 35.75 31.05 36.15 32.37 36.15a4.64 4.64 0 010 9.28M424.55 105.05a4.64 4.64 0 010-9.28c1.32 0 32.38-.62 32.38-36.15v-55a4.61 4.61 0 119.21 0v55c0 44.91-41.16 45.43-41.58 45.43"
          />
          <path
            className="prefix__cls-20"
            d="M410.52 88h-.4c-7.85-.69-22.69-8.2-22.69-31.62V4.64a4.6 4.6 0 119.2 0v51.7c0 20.87 13.71 22.33 14.29 22.38a4.64 4.64 0 01-.4 9.26M424.55 88a4.64 4.64 0 01-.4-9.26c1.43-.15 14.29-2.15 14.29-22.38V4.64a4.6 4.6 0 119.2 0v51.7c0 23.42-14.83 30.93-22.68 31.62h-.41M277.67 8.29a7.89 7.89 0 11-7.88-7.95 7.91 7.91 0 017.88 8M277.67 31.06a7.89 7.89 0 11-7.88-7.95 7.92 7.92 0 017.88 8M277.67 53.83a7.89 7.89 0 11-7.88-7.95 7.92 7.92 0 017.88 7.95M350.11 31.06a7.89 7.89 0 11-7.89-7.95 7.92 7.92 0 017.89 8M350.11 53.83a7.89 7.89 0 11-7.89-7.95 7.92 7.92 0 017.89 7.95M277.67 76.6a7.89 7.89 0 11-7.88-7.94 7.92 7.92 0 017.88 7.94M350.11 76.6a7.89 7.89 0 11-7.89-7.94 7.92 7.92 0 017.89 7.94M350.11 8.29a7.89 7.89 0 11-7.89-7.95 7.92 7.92 0 017.89 8M301.82 99.38a7.89 7.89 0 11-7.89-7.95 7.93 7.93 0 017.89 7.95M326 99.38a7.89 7.89 0 11-7.88-7.95 7.92 7.92 0 017.88 7.95"
          />
          <path
            className="prefix__cls-10"
            d="M235.12 88.69c-14.05-1.59-24.29-6.9-30.43-15.75-.07-.09-.13-.19-.2-.29A71.15 71.15 0 00221 62.22c7.93-6.73 20.89-20.58 18.67-37.6a28.35 28.35 0 00-16.1-22c-6.82-3.23-14.62-3.06-22 .48-9.84 4.75-18 15.16-20.72 26.52a81.27 81.27 0 00-.36 31.38 91.53 91.53 0 01-14.82 1.56V10.42a9.15 9.15 0 10-18.29 0v87.69a9.15 9.15 0 1018.29 0V81a108.64 108.64 0 0021-2.45 52.69 52.69 0 003 5c9.33 13.33 23.9 21.24 43.4 23.45a7.26 7.26 0 001 .06 9.22 9.22 0 001-18.38M198.67 34c1.46-6 5.92-11.87 10.86-14.25a8.74 8.74 0 013.83-1 5.45 5.45 0 012.44.56 9.86 9.86 0 015.75 7.7c.79 6.09-3.7 13.78-12.32 21.11a52.35 52.35 0 01-11.13 7.18 60.83 60.83 0 01.57-21.3M149.27 131h8.79a8.1 8.1 0 015.79 2.06 7.35 7.35 0 012.19 5.63 7.16 7.16 0 01-2.18 5.64 8.41 8.41 0 01-5.83 1.94h-4.77v8.29h-4zm8.29 11.73c3 0 4.49-1.34 4.49-4s-1.49-4.14-4.49-4.14h-4.3v8.18zM179.84 131h4v23.56h-4zM206.41 142.65l-8-11.66H203l5.65 8.33 5.69-8.33h4.54L211 142.65l8.1 11.9h-4.6l-5.84-8.55-5.84 8.56h-4.56zM233.5 131h3.89l7.35 17.25 7.34-17.25H256v23.56h-3.76v-15.3l-6.54 15.3h-1.89l-6.52-15.3v15.3h-3.79zM279.21 131h3.86l8.76 23.56h-4.06l-2-5.43h-9.3l-2 5.43h-4.09zm5.46 14.59l-3.55-10-3.55 10zM306.26 131h4v10.68l9-10.68H324l-9.73 11.53 10.36 12h-4.77l-9.64-11.18v11.18h-4zM339.09 131h14.85v3.55h-10.86V141h8.06v3.53h-8.06V151h10.86v3.54h-14.85zM368.08 131h9.16a7.74 7.74 0 015.62 1.92 7.35 7.35 0 012 5.56 7.87 7.87 0 01-1.2 4.41 6.46 6.46 0 01-3.39 2.6l4.45 9.07h-4.07l-4.25-8.63h-4.32v8.63h-4zm8.45 11.39a4.73 4.73 0 003.13-1 3.56 3.56 0 001.2-2.93c0-2.63-1.44-3.93-4.33-3.93h-4.46v7.84zM430.05 131h3.86l8.76 23.56h-4.06l-2-5.43h-9.3l-2 5.43h-4.08zm5.46 14.59l-3.55-10-3.55 10zM457.1 131h4v23.56h-4z"
          />
          <path
            className="prefix__cls-20"
            d="M47.79.92c-20.18 2.83-37 16.62-42.4 34.56A44.4 44.4 0 003.45 48c-.06 6.35 2.24 17.33 22.19 22.82 0 0 9.28 1.64 30.52 1.64s30.38-1.69 30.38-1.69c19.4-5.42 22.3-16.06 22.33-22.2v-.16c0-29-28.27-52-61.08-47.45M14.61 77.25c-5 1-11.16 4.51-14 14.68-2.51 9 3.28 18.77 7.38 24.84a2.18 2.18 0 003.87-2c-2.06-6-3.25-13-.47-17.83a23.1 23.1 0 012.71-3.84v.17zM97.7 77.25c5 1 11.16 4.51 14 14.68 2.51 9-3.28 18.77-7.38 24.84a2.18 2.18 0 01-3.87-2c2.06-6 3.26-13 .48-17.83a23.69 23.69 0 00-2.71-3.89v.17zM96.5 93L96 77.16l-.08-2.58a.72.72 0 00-.8-.58h-4.93l.71 19.8h4.85c.43 0 .77-.26.76-.59zM19.05 74h-2a.68.68 0 00-.64.57l-.08 2.59L15.81 93v.16c0 .33.33.59.76.6h4.85L22.15 74zM88.49 74H23.83l-.74 19.78C27.09 112 40 133.11 56 134h.39c16-.86 28.87-22 32.88-40.16z"
          />
          <path
            d="M88.47 151.79c0 2.34-14.47 4.23-32.32 4.23s-32.31-1.89-32.31-4.23 14.47-4.23 32.31-4.23 32.32 1.9 32.32 4.23"
            fill="#808184"
          />
          <path
            d="M56.16 65.1C21.84 65 18.72 54.82 18.78 49.74a21.79 21.79 0 011.4-7.34c3.93-10.53 16.06-18.63 30.66-20.29a50.47 50.47 0 015.32-.33 50.8 50.8 0 015.32.33c14.59 1.66 26.72 9.76 30.65 20.29a21.79 21.79 0 011.4 7.34c.06 5.08-3 15.22-37.37 15.36"
            fill="#f6f0de"
          />
          <path
            className="prefix__cls-50"
            d="M43.05 46c0 3.86-2.48 7-5.56 7s-5.56-3.14-5.56-7 2.49-7 5.56-7 5.56 3.13 5.56 7M80.38 46c0 3.86-2.49 7-5.56 7s-5.56-3.14-5.56-7 2.49-7 5.56-7 5.56 3.13 5.56 7M56.16 52a3.35 3.35 0 01-2.5-1.13l-.75-.87a.67.67 0 011-.9l.75.85a2 2 0 003 0l.75-.85a.68.68 0 011 0 .69.69 0 010 .95l-.75.85a3.33 3.33 0 01-2.5 1.1"
          />
        </g>
      </g>
    </SvgIcon>
  );
});

const Logo = memo((props) => {
  return (
    <SvgIcon
      style={{
        width: "100%",
        height: "100%",
        cursor: "pointer",
        ...props.style,
      }}
      viewBox="0 0 453.09 155.36"
    >
      <defs>
        <linearGradient
          id="prefix__linear-gradient"
          x1={264.95}
          y1={53.79}
          x2={435.87}
          y2={53.79}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#f68720" />
          <stop offset={0.82} stopColor="#fdbf2d" />
          <stop offset={1} stopColor="#ffcc30" />
        </linearGradient>
        <linearGradient
          id="prefix__linear-gradient-2"
          x1={24.03}
          y1={151.03}
          x2={90.17}
          y2={151.03}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#414042" />
          <stop offset={0.84} stopColor="#75777a" />
          <stop offset={1} stopColor="#808285" />
        </linearGradient>
        <linearGradient
          id="prefix__linear-gradient-3"
          x1={-4.81}
          y1={121.97}
          x2={126.69}
          y2={15.12}
          xlinkHref="#prefix__linear-gradient"
        />
        <style>
          {".prefix__cls-1{fill:#060608}.prefix__cls-5{fill:#231f20}"}
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <g id="prefix__Kuu_All_Cap" data-name="Kuu All Cap">
            <g id="prefix__Kuu2">
              <path
                className="prefix__cls-1"
                d="M401.71 101.14c-.38 0-38.25-.48-38.25-41.81V8.79a4.24 4.24 0 118.47-.41v50.95c0 32.9 28.56 33.28 29.78 33.28a4.27 4.27 0 010 8.53zM414.63 101.14a4.27 4.27 0 010-8.53c1.21 0 29.79-.58 29.79-33.28V8.79a4.24 4.24 0 118.47-.41v50.95c-.01 41.33-37.89 41.81-38.26 41.81z"
              />
              <path
                d="M401.72 85.43h-.38c-7.22-.64-20.82-7.56-20.82-29.12V8.79a4.24 4.24 0 118.48-.41v47.93c0 19.22 12.61 20.56 13.14 20.6a4.27 4.27 0 01-.36 8.52zm12.9 0a4.27 4.27 0 01-.36-8.52c1.31-.14 13.14-2 13.14-20.6V8.79a4.24 4.24 0 118.47-.41v47.93c0 21.56-13.65 28.46-20.87 29.1zM272.21 4.78a7.31 7.31 0 11-7.21 7.31 7.28 7.28 0 017.21-7.31zm0 21a7.31 7.31 0 11-7.21 7.27 7.28 7.28 0 017.21-7.31zm0 21A7.31 7.31 0 11265 54a7.28 7.28 0 017.21-7.3zm66.65-21a7.31 7.31 0 11-7.26 7.31 7.28 7.28 0 017.26-7.35zm0 21A7.31 7.31 0 11331.6 54a7.28 7.28 0 017.26-7.3zm-66.65 21A7.31 7.31 0 11265 75a7.28 7.28 0 017.21-7.35zm66.65 0A7.31 7.31 0 11331.6 75a7.28 7.28 0 017.26-7.35zm0-62.87a7.31 7.31 0 11-7.26 7.31 7.28 7.28 0 017.26-7.44zm-44.43 83.7a7.31 7.31 0 11-7.26 7.31 7.28 7.28 0 017.26-7.31zm22.22 0a7.31 7.31 0 11-7.26 7.31 7.28 7.28 0 017.26-7.31z"
                fill="url(#prefix__linear-gradient)"
              />
              <path
                className="prefix__cls-1"
                d="M240.31 86.09c-12.93-1.47-22.35-6.35-28-14.5l-.18-.26a66.07 66.07 0 0015.21-9.6c7.29-6.2 19.18-18.94 17.18-34.6A26.13 26.13 0 00229.7 6.9c-6.27-3-13.45-2.81-20.21.45-9.06 4.36-16.55 13.94-19.08 24.4a74.64 74.64 0 00-.41 28.84A85.64 85.64 0 01176.4 62V14a8.42 8.42 0 10-16.83-.58 5.61 5.61 0 000 .58v80.79a8.42 8.42 0 0016.83.58V79a99.58 99.58 0 0019.31-2.22 46.43 46.43 0 002.8 4.56c8.54 12.31 22 19.59 39.91 21.64h1a8.49 8.49 0 00.93-16.91zm-33.54-50.3c1.34-5.53 5.45-10.92 10-13.11a8 8 0 013.52-.91 5 5 0 012.25.51 9.09 9.09 0 015.29 7.09c.69 5.57-3.41 12.65-11.31 19.42a48.24 48.24 0 01-10.23 6.61 55.7 55.7 0 01.48-19.61z"
              />
            </g>
            <g id="prefix__Mascot2">
              <ellipse
                cx={57.1}
                cy={151.03}
                rx={33.07}
                ry={4.33}
                fill="url(#prefix__linear-gradient-2)"
              />
              <ellipse
                className="prefix__cls-1"
                cx={38.36}
                cy={46.71}
                rx={5.69}
                ry={7.16}
              />
              <ellipse
                className="prefix__cls-1"
                cx={76.56}
                cy={46.71}
                rx={5.69}
                ry={7.16}
              />
              <path
                className="prefix__cls-1"
                d="M57.46 52.88a3.37 3.37 0 01-2.55-1.16l-.77-.87a.69.69 0 010-1 .68.68 0 011 0l.77.87a2 2 0 002.82.24 1.36 1.36 0 00.24-.24l.77-.87a.68.68 0 011-.1.69.69 0 01.09 1l-.77.87a3.42 3.42 0 01-2.6 1.26z"
              />
              <path
                d="M48.9.6C28.25 3.49 11.08 17.6 5.52 36a45.34 45.34 0 00-2 12.77c-.06 6.5 2.29 17.72 22.71 23.35 0 0 9.5 1.68 31.22 1.68s31.1-1.8 31.1-1.8c19.85-5.55 22.81-16.42 22.84-22.71v-.16C111.39 19.45 82.47-4.1 48.9.6zm8.56 65.67c-35.12-.15-38.31-10.52-38.25-15.72a22.14 22.14 0 011.44-7.5c4-10.79 16.43-19 31.35-20.78 1.81-.2 3.63-.31 5.45-.33s3.63.13 5.44.33c14.93 1.7 27.35 10 31.37 20.78a22.13 22.13 0 011.43 7.5c.08 5.2-3.12 15.57-38.23 15.72zM15 78.7C9.83 79.77 3.53 83.31.62 93.72c-2.56 9.19 3.36 19.2 7.56 25.41a2.24 2.24 0 004-2C10 111 8.81 103.87 11.65 98.89a25 25 0 012.77-4v.17zm85 0c5.13 1.07 11.43 4.61 14.33 15 2.57 9.19-3.35 19.2-7.55 25.41a2.24 2.24 0 01-4-2c2.11-6.16 3.33-13.27.49-18.25a23.61 23.61 0 00-2.78-4V95zm-1.26 16.13l-.53-16.22-.08-2.61c0-.34-.38-.6-.83-.6h-5l.7 20.24h5c.45 0 .8-.28.79-.61zM19.49 75.38h-2a.71.71 0 00-.66.59l-.09 2.64-.52 16.22V95c0 .33.34.6.78.61h5l.75-20.24zm71.06 0H24.38l-.75 20.24c4.1 18.59 17.28 40.22 33.64 41.09a2.67 2.67 0 00.4 0C74 135.86 87.2 114.23 91.3 95.64z"
                fill="url(#prefix__linear-gradient-3)"
              />
            </g>
            <path
              className="prefix__cls-5"
              d="M159.56 131h9.06a17.9 17.9 0 013.58.34 8.23 8.23 0 012.94 1.19 6 6 0 012 2.27 7.87 7.87 0 01.74 3.6 8.25 8.25 0 01-.69 3.58 5.84 5.84 0 01-1.89 2.31 7.63 7.63 0 01-2.86 1.22 16.19 16.19 0 01-3.58.37h-3.93v9.5h-5.37zm5.37 10.33h3.58a7.07 7.07 0 001.4-.14 3.53 3.53 0 001.2-.46 2.5 2.5 0 00.87-.9 2.91 2.91 0 00.32-1.43 2.36 2.36 0 00-.43-1.49 2.8 2.8 0 00-1.1-.88 4.73 4.73 0 00-1.5-.4 14.54 14.54 0 00-1.58-.08h-2.76zM188.07 131h5.37v24.38h-5.37zM211.28 142.62L203.22 131H210l5.2 8.54 5-8.54h6.51l-7.89 11.54 8.81 12.84h-6.74l-6-9.81-5.79 9.81h-6.37zM237 131h8.13l5.61 15.91h.07l5.62-15.91h8.09v24.38h-5.37v-18.7h-.07l-6.41 18.7h-4.09l-6.17-18.7h-.07v18.7H237zM284.39 131h4.44l10.61 24.38h-6.06l-2.1-5.17h-9.47l-2 5.17h-5.92zm2.06 7.09l-3 7.58h6zM309 131h5.37v10.1l9.45-10.1h7L320 142.31l11.78 13.05h-7.51l-9.91-11.57v11.57H309zM340.72 131h16.57v5H346.1v4.54h10.57v5H346.1v5h11.81v5h-17.19zM369.1 131h9.43a16.7 16.7 0 013.53.36 8.2 8.2 0 012.93 1.21 6 6 0 012 2.27 7.73 7.73 0 01.74 3.56 7.18 7.18 0 01-1.34 4.39 6 6 0 01-3.93 2.29l6.2 10.3h-6.44l-5.1-9.75h-2.65v9.75h-5.37zm5.37 10.09h4.7a5 5 0 001.46-.31 2.51 2.51 0 001.09-.81 2.46 2.46 0 00.43-1.55 2.6 2.6 0 00-.38-1.48 2.53 2.53 0 00-1-.85 4.45 4.45 0 00-1.35-.39 10.72 10.72 0 00-1.48-.1h-3.51zM423.4 131h4.45l10.6 24.38h-6.06l-2.1-5.17h-9.47l-2 5.17h-5.92zm2.07 7.09l-3 7.58h6zM447.71 131h5.38v24.38h-5.38z"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
});

const KuuIcon = memo((props) => {
  return (
    <SvgIcon
      style={{
        width: "100%",
        height: "100%",
        cursor: "pointer",
        ...props.style,
      }}
      viewBox="0 0 156 222"
    >
      <defs>
        <path id="prefix__a" d="M.449.159h20.228v55.999H.45z" />
        <path id="prefix__c" d="M0 0h89.377v11.616H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M0 130.764c.24-1.21.42-2.436.729-3.628 1.436-5.538 3.774-10.611 7.885-14.71 3.118-3.107 6.865-5.106 11.16-6.076.142-.032.288-.044.495-.074-.101 2.935-.206 5.821-.3 8.707-.133 4.14-.242 8.28-.407 12.418-.019.467-.236.999-.528 1.37-2.555 3.243-4.694 6.67-5.254 10.88-.489 3.673-.191 7.307.609 10.89.555 2.49 1.292 4.942 2.017 7.39.516 1.742.019 3.22-1.465 3.956-1.504.744-2.986.295-4.02-1.244-3.728-5.55-7.173-11.263-9.13-17.71-.739-2.43-1.095-4.978-1.628-7.47-.037-.167-.108-.327-.163-.49v-4.209z"
          fill="#F1AF1D"
        />
        <path
          d="M79.152 89.579c2.778-.127 7.162-.277 11.54-.538 6.302-.375 12.548-1.193 18.67-2.778 4.86-1.258 9.56-2.927 13.62-6.003 3.935-2.98 6.29-6.81 6.26-11.893-.022-3.732-.767-7.322-2.147-10.775-2.837-7.104-7.693-12.606-13.86-16.961-11.089-7.83-23.562-11.103-37.03-10.924-5.76.077-11.405.963-16.922 2.6-9.091 2.696-17.225 7.052-23.83 13.992-4.899 5.146-8.136 11.148-9.201 18.219-.447 2.97-.552 5.949.56 8.838 1.513 3.934 4.447 6.557 7.978 8.606 4.724 2.74 9.907 4.186 15.212 5.244 9.091 1.815 18.306 2.22 29.15 2.373m-1.627 10.174c-6.916-.18-13.84-.22-20.746-.58-6.567-.343-13.124-.94-19.67-1.584-3.54-.348-6.826-1.76-10.1-3.08-5.534-2.228-10.626-5.175-14.8-9.524-4.818-5.022-7.362-11.054-7.393-17.981-.068-15.906 5.324-29.856 15.746-41.799C31.703 12.44 45.774 4.54 62.375 1.497c21.826-4 42.162-.08 60.39 12.887 12.083 8.595 20.573 19.964 25 34.218 1.903 6.129 2.827 12.419 2.635 18.83-.202 6.712-2.79 12.47-7.44 17.304-5.051 5.249-11.36 8.39-18.076 10.9-4.04 1.51-8.219 2.169-12.479 2.567-8.074.754-16.163 1.202-24.271 1.285-3.535.037-7.07.006-10.606.006 0 .087-.002.173-.003.26M32.937 101.775h89.294c.05.755.116 1.475.142 2.195.221 6.15.43 12.3.654 18.45.07 1.918.31 3.84.22 5.748-.07 1.527-.423 3.064-.821 4.55-3.187 11.912-8.165 22.994-15.502 32.95-4.36 5.916-9.41 11.132-15.842 14.831-4.587 2.638-9.475 4.429-14.86 4.02-5.12-.39-9.729-2.367-13.99-5.177-6.233-4.11-11.146-9.533-15.354-15.623-7.097-10.269-11.923-21.558-14.728-33.694-.426-1.846-.137-3.873-.076-5.813.162-5.145.378-10.29.577-15.434.089-2.31.188-4.618.286-7.003"
          fill="#F1AE1C"
        />
        <g transform="translate(134.5 106.077)">
          <mask id="prefix__b" fill="#fff">
            <use xlinkHref="#prefix__a" />
          </mask>
          <path
            d="M.45.159c6.388 1.395 11.328 4.733 14.82 10.076 3.43 5.25 5.566 10.972 5.398 17.352-.13 4.914-1.554 9.532-3.507 14.001-2.033 4.653-4.659 8.972-7.499 13.164-.93 1.376-2.412 1.757-3.85 1.075-1.448-.686-2.094-2.14-1.566-3.75 1.158-3.537 2.079-7.118 2.629-10.81 1.04-6.982-.536-13.176-5.177-18.556-.32-.371-.532-.955-.551-1.45C.89 14.441.675 7.62.45.8c-.005-.178 0-.356 0-.64"
            fill="#F1AF1D"
            mask="url(#prefix__b)"
          />
        </g>
        <path
          d="M125.555 129.117l-1.02-27.385h4.48c.732 0 1.464-.022 2.195.007 1.005.039 1.274.324 1.307 1.326.193 5.992.378 11.985.576 17.978.072 2.158.168 4.316.265 6.474.06 1.321-.196 1.598-1.557 1.6-2.038.002-4.076 0-6.246 0M30.673 101.75l-1.015 27.355c-2.37 0-4.646.026-6.92-.015-.652-.013-.914-.492-.892-1.147.134-3.923.253-7.848.38-11.772.116-3.558.236-7.117.357-10.675.03-.851.118-1.703.098-2.553-.019-.785.377-1.17 1.075-1.18 2.274-.034 4.549-.013 6.917-.013"
          fill="#F1AF1E"
        />
        <path
          d="M78.03 71.46c1.757-.024 3.097-1.358 4.187-3.002.15-.227-.023-.935-.258-1.083-.29-.183-.892-.155-1.2.037-.445.279-.737.797-1.114 1.193-1.188 1.247-2.922 1.235-4.104-.025-.375-.4-.67-.918-1.118-1.19-.306-.186-.914-.186-1.2.007-.229.155-.374.86-.217 1.09 1.104 1.632 2.441 2.975 5.025 2.972m-18.507-8.332c.018-2.055-.451-3.984-1.483-5.763-2.705-4.666-8.115-5.296-11.484-1.342-3.333 3.91-3.236 10.59.21 14.403 2.776 3.07 7.037 3.17 9.923.19 2.013-2.078 2.8-4.647 2.834-7.488m36.164-.004c.005 2.739.815 5.12 2.395 7.023 2.782 3.352 7.266 3.532 10.232.413 3.609-3.797 3.713-10.762.219-14.674-2.982-3.338-7.535-3.294-10.422.137-1.718 2.043-2.43 4.457-2.424 7.101M79.152 89.58c-10.844-.153-20.059-.558-29.15-2.373-5.305-1.059-10.488-2.503-15.211-5.244-3.531-2.049-6.466-4.672-7.98-8.606-1.11-2.89-1.006-5.869-.56-8.838 1.066-7.071 4.303-13.073 9.202-18.22 6.605-6.939 14.739-11.296 23.83-13.992 5.517-1.636 11.162-2.523 16.922-2.6 13.468-.178 25.941 3.094 37.03 10.925 6.167 4.355 11.023 9.857 13.86 16.961 1.38 3.453 2.125 7.043 2.147 10.774.03 5.084-2.325 8.913-6.26 11.894-4.06 3.076-8.76 4.745-13.62 6.003-6.122 1.585-12.368 2.403-18.67 2.778-4.378.26-8.762.411-11.54.538"
          fill="#FEFAEA"
        />
        <path
          d="M108.534 55.885c-2.982-3.338-7.536-3.294-10.422.137-1.718 2.043-2.43 4.457-2.424 7.101.005 2.739.815 5.12 2.395 7.023 2.782 3.352 7.266 3.532 10.232.413 3.609-3.797 3.712-10.762.219-14.674M46.557 56.022c-3.333 3.911-3.236 10.59.21 14.403 2.776 3.07 7.037 3.17 9.923.19 2.013-2.078 2.8-4.647 2.834-7.488.018-2.055-.451-3.984-1.483-5.763-2.705-4.666-8.115-5.295-11.484-1.342M81.959 67.375c-.29-.183-.892-.155-1.199.038-.446.278-.738.796-1.115 1.192-1.188 1.247-2.922 1.235-4.104-.025-.375-.4-.67-.918-1.118-1.19-.306-.186-.914-.186-1.2.007-.229.155-.374.86-.217 1.091 1.104 1.631 2.441 2.974 5.025 2.971 1.756-.023 3.096-1.357 4.186-3 .15-.228-.023-.936-.258-1.084"
          fill="#101111"
        />
        <g transform="translate(33 209.5)">
          <mask id="prefix__d" fill="#fff">
            <use xlinkHref="#prefix__c" />
          </mask>
          <path
            d="M52.758 11.616c-1.337-.001-2.673-.005-4.01-.004-4.04 0-8.08.003-12.12.004-7.053-.438-14.122-.713-21.154-1.376-4.041-.382-8.03-1.356-12.027-2.15-.9-.18-1.783-.635-2.58-1.12-1.143-.696-1.179-1.678.026-2.22 1.76-.79 3.61-1.473 5.49-1.895C12.192 1.547 18.114.953 24.048.667c7.425-.359 14.86-.62 22.293-.663 9.144-.052 18.28.358 27.376 1.382 4.266.48 8.535 1.005 12.639 2.358.8.263 1.59.632 2.297 1.087.953.612.972 1.419.01 2.028-.837.53-1.77 1.034-2.726 1.226-3.993.806-7.984 1.773-12.025 2.155-7.033.663-14.102.938-21.155 1.376"
            fill="#4C4C4D"
            mask="url(#prefix__d)"
          />
        </g>
      </g>
    </SvgIcon>
  );
});

export { LogoFooter, Logo, KuuIcon };
