import React from "react";
import GoogleStoreLogo from "../../../images/svg/GoogleStoreLogo.svg";
import AppStoreLogo from "../../../images/svg/AppStoreLogo.svg";
import { APPLE_STORE, GOOGLE_STORE } from "../../../utils/config";

const AppStore = () => {
  return (
    <div>
      <div
        className="app-store"
        style={{
          display: "flex",
          gap: "1em",
        }}
      >
        <a href={`${APPLE_STORE}`} style={{ textDecoration: "none" }}>
          <AppStoreLogo style={{ maxWidth: 150, width: "100%" }} />
        </a>
        <a href={`${GOOGLE_STORE}`} style={{ textDecoration: "none" }}>
          <GoogleStoreLogo style={{ maxWidth: 150, width: "100%" }} />
        </a>
      </div>
      <h5>Download free today</h5>
    </div>
  );
};

export default AppStore;
