import React from "react";
import styled from "@emotion/styled";
import { useStaticQuery, graphql } from "gatsby";
import { useMediaQuery } from "@material-ui/core";
import SlideContent from "./Slide/index";

const WrapperContainer = styled.div`
  max-width: 1170px;
  margin: 0 auto;

  .wrapper-gallery {
    padding: 1.5em 1em;
    max-width: 900px;
    min-height: 100px;
    grid-gap: 0.5em;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  }
  .wrapper-text {
    max-width: 900px;
    margin: 0 auto;
    padding: 0em 1em;
    & h1 {
      margin: 0.5em 0em;
      background: linear-gradient(
        to left bottom,
        #f9993c,
        #fb9f33,
        #fca628,
        #fcad1a,
        #fbb400
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: clamp(1.5rem, 2.5vw, 2.5rem);
    }
    & p {
      margin: 0.5em 0em;
      font-weight: 300;
    }
  }
  .wrapper-card {
    text-align: center;
    & h2 {
      font-weight: 300;
      text-align: center;
      font-size: clamp(0.8rem, 1.7vw, 1rem);
    }
  }
  .image-card {
    @media (max-width: 592px) {
      width: max-content;
      margin: 0 auto;
      border-radius: 10px;
      background-color: #fff;
      padding: 1em;
      box-shadow: 0 6px 31px 0 rgba(0, 0, 0, 0.1),
        0 105px 136px 0 rgba(0, 0, 0, 0);
    }
  }
`;

const AppShowCase = () => {
  const { showCase } = useStaticQuery(graphql`
    query {
      showCase: allFile(
        filter: { relativeDirectory: { eq: "Mobile/ShowCase" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                quality: 70
                width: 200
                blurredOptions: { width: 100 }
              )
            }
            base
            id
          }
        }
      }
    }
  `);
  const matches = useMediaQuery("(max-width:592px)");
  return (
    <WrapperContainer>
      <div className="wrapper-gallery">
        <div className="wrapper-card">
          <SlideContent
            image={showCase}
            view={matches ? 1 : 4}
            display={matches ? "block" : "none"}
            text={[
              <h2>
                Compose pro-quality <br />
                <strong>e-commerce product image</strong>
              </h2>,
              <h2>
                Create Compelling <strong>advertising</strong>
              </h2>,
              <h2>
                Make engaging <strong>visual</strong> <br />
                for social media
                <strong> content</strong>
              </h2>,
              <h2>
                Design personal <br />
                <strong>masterpiece</strong>
              </h2>,
            ]}
          />
        </div>
      </div>
    </WrapperContainer>
  );
};
export default AppShowCase;
