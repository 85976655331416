import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { StaticImage } from "gatsby-plugin-image";
import { withStyles, makeStyles, Tabs, Tab, Slide } from "@material-ui/core";
import {
  BgRemove,
  Ai,
  Cool,
  DesignText,
  FreePic,
  Instant,
  RoyaltyFree,
  Templates,
} from "./Icon";

const WrapperImage = styled.div`
  max-width: 300px;
  margin: 0 auto;
  @media (max-width: 592px) {
    max-width: 240px;
  }
`;

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Slide
          direction="left"
          mountOnEnter
          unmountOnExit
          in={Boolean(value === index)}
        >
          {children}
        </Slide>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const StyledTabs = withStyles({
  flexContainer: {
    display: "grid",
    padding: "0.5em 0.5em",
    gridTemplateColumns: "repeat(1,1fr)",
    justifyItems: "center",
    gridGap: "0.5em",
    "@media(max-width: 592px)": {
      gridTemplateColumns: "repeat(4,1fr)",
    },
  },
  fixed: {
    maxWidth: 300,
    "@media(max-width: 592px)": {
      width: "100%",
      maxWidth: "unset",
    },
  },
  indicator: {
    display: "none",
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    width: "100%",
    textTransform: "none",
    color: "#000",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: "clamp(0.5rem, 2.4vw, 0.8rem)",
    padding: "6px 10px",
    maxWidth: 170,
    minWidth: 60,
    minHeight: 50,
    borderRadius: 10,
    border: "1px solid #ebebeb",
    marginRight: 0,
    "&:focus": {
      // backgroundColor: "#fbb400",
      opacity: 1,
    },
    "&$selected": {
      color: "#fff",
      "& *": {
        fill: "#fff",
      },
      border: "1px solid transparent",
      backgroundImage:
        "linear-gradient(to right bottom, #f69724, #f89125, #fa8c27, #fb8629, #fd802b)",
    },
  },
  wrapper: { maxHeight: 60 },
  selected: { backgroundColor: "#fbb400" },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    alignItems: "center",
    gridAutoFlow: "column",
    height: "100%",
    maxWidth: 700,
    margin: "0 auto",
    padding: "2em 0em",
    "@media(max-width: 592px)": {
      gridAutoFlow: "row",
    },
  },
}));

const AboutApp = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div
      style={{
        margin: "2em auto",
      }}
    >
      <h2
        style={{
          maxWidth: 700,
          padding: "0em 0.5em",
          margin: "0 auto",
          textAlign: "center",
          fontSize: "clamp(1.2rem, 2.5vw, 1.7rem)",
        }}
      >
        What you will love about us
      </h2>
      <div className={classes.root}>
        <StyledTabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
        >
          <StyledTab
            icon={
              <BgRemove
                style={{ maxWidth: 25, width: "100%", height: "100%" }}
              />
            }
            label={"Background removal"}
            {...a11yProps(0)}
          />
          <StyledTab
            icon={
              <RoyaltyFree
                style={{ maxWidth: 50, width: "100%", height: "100%" }}
              />
            }
            label={"Royalty free cutouts"}
            {...a11yProps(1)}
          />
          <StyledTab
            icon={
              <FreePic
                style={{ maxWidth: 50, width: "100%", height: "100%" }}
              />
            }
            label={"Free pics & background"}
            {...a11yProps(2)}
          />
          <StyledTab
            icon={
              <DesignText
                style={{ maxWidth: 25, width: "100%", height: "100%" }}
              />
            }
            label={"Designed text"}
            {...a11yProps(3)}
          />
          <StyledTab
            icon={
              <Cool style={{ maxWidth: 25, width: "100%", height: "100%" }} />
            }
            label={"Cool elements"}
            {...a11yProps(4)}
          />
          <StyledTab
            icon={
              <Ai style={{ maxWidth: 25, width: "100%", height: "100%" }} />
            }
            label={"AI Harmonization"}
            {...a11yProps(5)}
          />
          <StyledTab
            icon={
              <Templates
                style={{ maxWidth: 25, width: "100%", height: "100%" }}
              />
            }
            label={"Inspirational templates"}
            {...a11yProps(6)}
          />
          <StyledTab
            icon={
              <Instant
                style={{ maxWidth: 25, width: "100%", height: "100%" }}
              />
            }
            label={"Instant resize"}
            {...a11yProps(7)}
          />
        </StyledTabs>
        <TabPanel value={value} index={0}>
          <WrapperImage>
            <StaticImage
              placeholder="tracedSVG"
              quality="60"
              src="../../../images/Mobile/AboutUs/A-1.png"
              alt="selling"
            />
          </WrapperImage>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <WrapperImage>
            <StaticImage
              loading="lazy"
              placeholder="tracedSVG"
              quality="60"
              src="../../../images/Mobile/AboutUs/A-2.png"
              alt="selling"
            />
          </WrapperImage>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <WrapperImage>
            <StaticImage
              loading="lazy"
              placeholder="tracedSVG"
              quality="60"
              src="../../../images/Mobile/AboutUs/A-3.png"
              alt="selling"
            />
          </WrapperImage>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <WrapperImage>
            <StaticImage
              loading="lazy"
              placeholder="tracedSVG"
              quality="60"
              src="../../../images/Mobile/AboutUs/A-4.png"
              alt="selling"
            />
          </WrapperImage>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <WrapperImage>
            <StaticImage
              loading="lazy"
              placeholder="tracedSVG"
              quality="60"
              src="../../../images/Mobile/AboutUs/A-5.png"
              alt="selling"
            />
          </WrapperImage>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <WrapperImage>
            <StaticImage
              loading="lazy"
              placeholder="tracedSVG"
              quality="60"
              src="../../../images/Mobile/AboutUs/A-6.png"
              alt="selling"
            />
          </WrapperImage>
        </TabPanel>
        <TabPanel value={value} index={6}>
          <WrapperImage>
            <StaticImage
              loading="lazy"
              placeholder="tracedSVG"
              quality="60"
              src="../../../images/Mobile/AboutUs/A-7.png"
              alt="selling"
            />
          </WrapperImage>
        </TabPanel>
        <TabPanel value={value} index={7}>
          <WrapperImage>
            <StaticImage
              loading="lazy"
              placeholder="tracedSVG"
              quality="60"
              src="../../../images/Mobile/AboutUs/A-8.png"
              alt="selling"
            />
          </WrapperImage>
        </TabPanel>
      </div>
    </div>
  );
};

export default AboutApp;
